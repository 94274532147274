import { FilterProps } from 'react-table'

export enum SCREENING_PROPERTY_VALUE_TYPE {
	int = 'Int',
	string = 'String',
	none = 'None',
}

export type SurveyDefinitionFromServer = {
	CreatedBy: number
	Deleted: boolean
	ExternalId: string
	LastChangedBy: number
	Name: string
	OrgId: number
	DeptId: number
	SourceDeptId: number
	SourceOrgId: number
	PostProcessingRules: string
	SurveyPages: Array<
		IntroPageType | BasicDetailsPageType | ChoicesPageType | LastPageType
	>
	ConceptId: number
}

export type AnswerOption = {
	AllowOpenAnswer: boolean
	ExternalId: string
	HeaderTextKey: string
	NicNameTextKey: string
	IngressTextKey: string
	SubOptionHeaderTextKey: string
	SubOptionNicknameTextKey: string
	SubOptionIngressTextKey: string
	SubOptionType: string
	ImageId: number
	MultipleChoiceSubOption: boolean
	Order: number
	PageOffset: number
	Properties: string
	ShowIngress: boolean
	Tag: string
	TriggersPageOffset: boolean
	SubOption?: Array<AnswerOption>
	ThresholdId?: number
}

export type IntroPageType = {
	$type: string
	AnsweredHeaderTextKey: string
	AnsweredIngressTextKey: string
	Changed: boolean
	ExternalId: string
	HeaderTextKey: string
	NicNameTextKey: string
	IngressTextKey: string
	ImageId: number
	VideoId: number
	PageNumber: number
	ShowPage: boolean
	HideWhenNotAnswered: boolean
}

export type BasicDetailsPageType = {
	$type: string
	Changed: boolean
	ExternalId: string
	HeaderTextKey: string
	NicNameTextKey: string
	IngressTextKey: string
	ImageId: number
	VideoId: number
	PageNumber: number
	ShowPage: boolean
	ShowBirthdayQuestion: boolean
	ShowGenderQuestion: boolean
	ShowHeightQuestion: boolean
	ShowWeightQuestion: boolean
	FindBmi: boolean
	BmiSpecification: string
	ShowOnlyYear: boolean
	ObservationId: number,
}

export type ChoicesPageType = {
	$type: string
	AnswerOptions: Array<AnswerOption>
	Changed: boolean
	ExternalId: string
	HeaderTextKey: string
	NicNameTextKey: string
	IngressTextKey: string
	ImageId: number
	VideoId: number
	PageNumber: number
	ShowPage: boolean
	VerticalAlign: boolean
	Properties: string
	ObservationId: number
}

export type LastPageType = {
	$type: 'LastPage'
	Changed: boolean
	ExternalId: string
	HeaderTextKey: string
	NicNameTextKey: string
	IngressTextKey: string
	ImageId: number
	VideoId: number
	PageNumber: number
	ShowPage: boolean
}

export type UserAnswersType = {
	ExternalId: string
	TimeStamp: number
	Deleted: boolean
	PageAnswers: Array<PageAnswer>
}

export type PageAnswer = {
	$type: string
	ExternalId: string
	SurveyPageId: string
	Tags?: string
	Selected?: string
	SliderLevel?: number
	Birthday?: string
	Gender?: number
	Height?: number
	Weight?: number
}

export type SurveyDefinitionQuestionType =
	| SurveyStartType
	| BasicQuestionType
	| ChoicesQuestionType
	| SurveySubmitType

export type SurveyDefinitionType = {
	id: string | null
	createdBy: number
	name: string
	questions: Array<SurveyDefinitionQuestionType>
	sourceOrgId: number
	sourceDeptId: number
	postProcessingRules: { tagRules: TagRuleType[] }
	conceptId: number
}

export type TagRuleType = {
	id: string
	index: number
	tagFilters: string[]
	tagsToInsert: string[]
}

export type TranslationType = {
	changed: boolean
	key: string
	text: string
	timeStamp: number
}

export type TranslationObjectType = {
	[key: string]: TranslationType
}

export type TranslationFieldNamesType =
	| 'en-US'
	| 'nb-NO'
	| 'sv-SE'
	| 'da-DK'
	| 'fi-FI'

export type TranslationsType = {
	[t in TranslationFieldNamesType]: TranslationObjectType
}

export type SurveyStartType = {
	id: string
	changed: boolean
	order: number
	visible: boolean
	headerTextKey: string
	nicknameTextKey: string
	ingressTextKey: string
	choicesVariant: string
	answeredHeaderTextKey: string
	answeredIngressTextKey: string
	hideWhenNotAnswered: boolean
}

export type SurveySubmitType = {
	id: string
	changed: boolean
	order: number
	visible: boolean
	headerTextKey: string
	nicknameTextKey: string
	ingressTextKey: string
	choicesVariant: 'SurveySubmit'
}

export type bmiTagRangeType = {
	id: string
	bmiStart: number
	bmiEnd: number
	bmiTag: string
}

export type BasicQuestionType = {
	id: string
	changed: boolean
	order: number
	visible: boolean
	headerTextKey: string
	nicknameTextKey: string
	ingressTextKey: string
	choicesVariant: string
	showGenderQuestion: boolean
	showBirthdayQuestion: boolean
	showWeightQuestion: boolean
	showHeightQuestion: boolean
	calculateBmi: boolean
	bmiTagRanges: bmiTagRangeType[]
	showOnlyYear: boolean
	observationId: number
}

export type ChoicesQuestionType = {
	id: string
	changed: boolean
	order: number
	visible: boolean
	headerTextKey: string
	nicknameTextKey: string
	ingressTextKey: string
	imageId?: number
	videoId?: number
	choicesVariant: string
	alignment: 'vertical' | 'horizontal'
	choices: Array<ChoiceType>
	observationId: number
}

export type RecommendationPropertyType = {
	id: string
	type: string
	grade: number
}

export type ScreeningPropertyType = {
	[key: string]: unknown
	id: string
	title: string
	value?: string
	children?: ScreeningPropertyType[]
}

export type ChoiceType = {
	id: string
	order: number
	offset: number
	tags: string
	headerTextKey: string
	nicknameTextKey: string
	ingressTextKey: string
	imageId: number
	thresholdId: number
	properties: {
		screening: ScreeningPropertyType[]
		recommendation: RecommendationPropertyType[]
	}
	subQuestionHeaderTextKey?: string
	subQuestionNicknameTextKey?: string
	subQuestionIngressTextKey?: string
	choicesVariant?: string
	choices?: Array<ChoiceType>
}

export type ApiDataType = {
	surveyDefinition: SurveyDefinitionType | undefined
	translations: TranslationsType | undefined
}

export type HandlerApiDataType = {
	surveyDefinition: SurveyDefinitionType | undefined
	translations: HandlerTranslationsType
	userAnswers: {
		deleted: boolean
		id: string
		questionAnswers: Array<QuestionAnswerType>
		timeStamp: number
	}
	loggedInRole: number
}

export type HandlerTranslationsType = {
	[t in TranslationFieldNamesType]?: LanguageTranslationsType
}

export type LanguageTranslationsType = {
	[translationKey: string]: string
}

export type QuestionAnswerType = {
	id: string
	questionId: string
	type: string
	answer: ChoiceGroupAnswerType[] | SliderAnswerType | BasicAnswerType
	tags: string | Array<string>
	properties?: {
		recommendation: RecommendationPropertyType[]
		screening: ScreeningPropertyType[]
	}
}

export type BasicAnswerType = {
	birthday: string
	gender: string
	weight: number
	height: number
}

export type SliderAnswerType = {
	sliderIndex: number
}

export type ChoiceGroupAnswerType = {
	id: string
	selected: boolean
	answers?: ChoiceGroupAnswerType[]
}

export type NewTranslation = {
	Culture: string
	$type: string
	Key: string
	Text: string
}

export type EmptyDataSet = {
	surveyDefinition: undefined
	translations: undefined
}

export type UpdateQuestionVariantType = {
	questionIndex: number
	newQuestionVariant: string
}

export type DeleteQuestionPayloadType = {
	questionIndex: number
}

export type ToggleVisiblePayloadType = {
	questionIndex: number
}

export type SaveTagRulesPayloadType = {
	tagRules: TagRuleType[]
}

export type QuestionActionType = {
	type: QuestionActionTypes | ChoiceActionTypes
	payload:
		| string
		| number
		| UpdateAlignmentPayloadType
		| NewSubchoicePayload
		| AddQuestionChoicePayloadType
		| UpdateQuestionPositionPayloadType
		| UpdateQuestionPayloadType
		| AddChoicePropertiesPayloadType
		| DeleteChoicePropertiesPayloadType
		| UpdateBasicQuestionPayloadType
		| UpdateChoicePayloadType
		| UpdateChoicePositionPayloadType
		| addScreeningPropertyPayloadType
		| updateScreeningPropertyValuePayloadType
		| deleteScreeningPropertyPayloadType
}

export type ScreeningPropertySettingsType = {
	id: string
	title: string
	valueType: string
	valueRange: string
	children?: ScreeningPropertySettingsType[]
}

type addScreeningPropertyPayloadType = {
	choiceIndex: number
	parentChoiceIndex: number
	selectedPropertyId: string
	propertySettings: ScreeningPropertySettingsType
	selectedPropertyParentId?: string
}

export type updateScreeningPropertyValuePayloadType = {
	parentChoiceIndex: number
	choiceIndex: number
	selectedPropertyId: string
	value: string
}

export type deleteScreeningPropertyPayloadType = {
	parentChoiceIndex: number
	choiceIndex: number
	selectedPropertyId: string
}

type ChoiceActionTypes =
	| 'updateChoice'
	| 'updateChoicePosition'
	| 'deleteChoice'
	| 'addChoiceProperties'
	| 'deleteChoiceProperties'
	| 'addScreeningProperty'
	| 'updateScreeningPropertyValue'
	| 'deleteScreeningProperty'
	| 'updateObservationId'

type QuestionActionTypes =
	| 'updateQuestion'
	| 'updateQuestionType'
	| 'updateBasicQuestion'
	| 'addChoice'

export type workoutProgramType = {
	$id: string
	Id: number
	Guid: string
	Title: string
	Tags: Array<string>
	Culture: string
}

export type TranslationActionType = {
	type: 'updateTranslation' | 'addNewTranslation' | 'deleteTranslation'
	payload: AddNewTranslationPayloadType | UpdateTranslationsPayloadType
}

export type UpdateTranslationsPayloadType = {
	key: string
	translateTo: TranslationFieldNamesType
	text: string
}

export type AddNewTranslationPayloadType = {
	headerTextKey?: string
	nicknameTextKey?: string
	ingressTextKey?: string
	subQuestionHeaderTextKey?: string
	subQuestionNicknameTextKey?: string
	subQuestionIngressTextKey?: string
	text?: string
}

export type AddQuestionChoicePayloadType = {
	questionIndex: number
}

export type UpdateAlignmentPayloadType = {
	alignment: 'horizontal' | 'vertical'
}

export type AddChoicePropertiesPayloadType = {
	parentChoiceIndex: number
	choiceIndex: number
	type: string
	id: string
	grade: number
}

export type DeleteChoicePropertiesPayloadType = {
	parentChoiceIndex?: number
	choiceIndex: number
}

export type UpdateQuestionPayloadType = {
	choicesVariant?: string
	visible?: boolean
	imageId?: number
	videoId?: number
	alignment?: string
	nicknameTextKey?: string
	subQuestionHeaderTextKey?: string
	subQuestionNicknameTextKey?: string
	subQuestionIngressTextKey?: string
	hideWhenNotAnswered?: boolean
	observationId?: number
}

export type UpdateQuestionPositionPayloadType = {
	newIndex: number
	oldIndex: number
}

export type UpdateBasicQuestionPayloadType = {
	visible?: boolean
	showGenderQuestion?: boolean
	showBirthdayQuestion?: boolean
	showWeightQuestion?: boolean
	showHeightQuestion?: boolean
	calculateBmi?: boolean
	bmiTagRanges?: {
		bmiStart: number | ''
		bmiEnd: number | ''
		bmiTag: string
	}[]
	showOnlyYear?: boolean
	observationId?: number
}

export type UpdateChoicePayloadType = {
	parentChoiceIndex?: number
	choiceIndex: number
	offset?: number
	tags?: string
	imageId?: number
	choicesVariant?: string
	nicknameTextKey?: string
	subQuestionHeaderTextKey?: string
	subQuestionNicknameTextKey?: string
	subQuestionIngressTextKey?: string
	thresholdId?: number
}

export type UpdateChoicePositionPayloadType = {
	parentChoiceIndex?: number
	newIndex: number
	oldIndex: number
}

export type DeleteChoicePayloadType = {
	parentChoiceIndex?: number
	choiceIndex: number
}

export type NewSubchoicePayload = {
	parentChoiceIndex?: number
	headerTextKey: string
	ingressTextKey: string
	subQuestionHeaderTextKey?: string
	subQuestionIngressTextKey?: string
}

export type SurveyQueryParams = {
	userId?: string
	deptId?: string
	hash?: string
	culture?: string
	questionIndex?: string
	orgToRequest?: string
	deptToRequest?: string
}

export type ValidVisualQuestionType =
	| BasicQuestionType
	| ChoicesQuestionType
	| SurveySubmitType

export type QuestionPositionType = {
	questionIndex: number
	id: string
	x: number
	y: number
	height: number
	width: number
	choice?: ChoicePositionType
}

export type ChoicePositionType = {
	id: string
	x: number
	y: number
	height: number
	width: number
}

export type TagsSortedBySkipsType = { [key: string]: string[] }

export type TagPreviewColumnsType = Array<{
	Header: string | React.ReactFragment
	accessor: string
	Filter?: (args: FilterProps<Record<string, unknown>>) => JSX.Element
	filter?: string
}>

export type TagRulesActionType = {
	type:
		| 'updateIndex'
		| 'addNewRule'
		| 'deleteRule'
		| 'addTagToInsert'
		| 'deleteTagToInsert'
		| 'addTagFilter'
		| 'deleteTagFilter'
	payload?:
		| { tagRuleIndex: number }
		| UpdateIndexPayload
		| AddTagToInsertPayload
		| AddTagFilterPayload
		| DeleteTagPayload
}

export type AddTagToInsertPayload = {
	tagRuleIndex: number
	newTagToInsert: string
}

export type DeleteTagPayload = {
	tagRuleIndex: number
	tagIndex: number
}

export type AddTagFilterPayload = {
	tagRuleIndex: number
	newFilterTag: string
}

export type UpdateIndexPayload = {
	tagRuleIndex: number
	newIndex: number
}

export type EditorActionType = {
	type:
		| 'setData'
		| 'saveQuestion'
		| 'addQuestion'
		| 'copyQuestion'
		| 'updateQuestionType'
		| 'deleteQuestion'
		| 'updateQuestionPosition'
		| 'toggleVisibleQuestion'
		| 'addNewTranslation'
		| 'saveTranslations'
		| 'saveTagRules'
	payload:
		| string
		| undefined
		| ApiDataType
		| EmptyDataSet
		| DeleteQuestionPayloadType
		| ToggleVisiblePayloadType
		| SurveyStartType
		| ChoicesQuestionType
		| BasicQuestionType
		| UpdateQuestionPositionPayloadType
		| UpdateQuestionVariantType
		| TranslationsType
		| AddNewTranslationPayloadType
		| SaveTagRulesPayloadType
}

export type ConceptActionType = {type: 'setConcepts', payload: SurveyConcept[]}

export type EditorDispatchType = (action: EditorActionType) => void

export type ConceptDispatchType = (action: ConceptActionType) => void

export type HandlerStateType = {
	surveyDefinition: SurveyDefinitionType
	confirmedAnswers: QuestionAnswerType[]
	potentialAnswers: QuestionAnswerType[]
	slug: string | undefined
}

export type HandlerActionType = {
	type:
		| 'navigateForward'
		| 'navigateBack'
		| 'deleteConfirmedAnswer'
		| 'updatePotentialAnswers'
	payload?:
		| UpdatePotentialBasicAnswerType
		| UpdatePotentialSliderAnswerType
		| UpdatePotentialChoicesAnswerType
}

export type HandlerDispatchType = (action: HandlerActionType) => void

export type UpdatePotentialBasicAnswerType = {
	birthday?: string
	gender?: string
	weight?: string
	height?: string
}

export type UpdatePotentialSliderAnswerType = {
	sliderIndex: number
}

export type UpdatePotentialChoicesAnswerType = {
	choiceId: string
	isChoiceChecked: boolean
	choicesVariant: string
	parentChoiceIndex?: number
}

// Typeguards
export const isTranslationFieldName = (
	variableToCheck: unknown
): variableToCheck is TranslationFieldNamesType =>
	(variableToCheck as TranslationFieldNamesType) === 'en-US' ||
	(variableToCheck as TranslationFieldNamesType) === 'nb-NO' ||
	(variableToCheck as TranslationFieldNamesType) === 'sv-SE' ||
	(variableToCheck as TranslationFieldNamesType) === 'da-DK' ||
	(variableToCheck as TranslationFieldNamesType) === 'fi-FI'

export const isQuestionAnswerType = (
	variableToCheck: unknown
): variableToCheck is QuestionAnswerType =>
	(variableToCheck as QuestionAnswerType).id !== undefined &&
	(variableToCheck as QuestionAnswerType).questionId !== undefined &&
	(variableToCheck as QuestionAnswerType).type !== undefined &&
	(variableToCheck as QuestionAnswerType).answer !== undefined &&
	(variableToCheck as QuestionAnswerType).tags !== undefined

export const isBasicAnswerType = (
	variableToCheck: unknown
): variableToCheck is BasicAnswerType =>
	(variableToCheck as BasicAnswerType).birthday !== undefined &&
	(variableToCheck as BasicAnswerType).gender !== undefined &&
	(variableToCheck as BasicAnswerType).weight !== undefined &&
	(variableToCheck as BasicAnswerType).height !== undefined

export const isChoiceGroupAnswerType = (
	variableToCheck: unknown
): variableToCheck is ChoiceGroupAnswerType =>
	(variableToCheck as ChoiceGroupAnswerType)?.id !== undefined &&
	(variableToCheck as ChoiceGroupAnswerType)?.selected !== undefined

export const isSliderAnswerType = (
	variableToCheck: unknown
): variableToCheck is SliderAnswerType =>
	(variableToCheck as SliderAnswerType).sliderIndex !== undefined

export const isIntroPageType = (
	variableToCheck: unknown
): variableToCheck is IntroPageType =>
	(variableToCheck as IntroPageType).AnsweredHeaderTextKey !== undefined &&
	(variableToCheck as IntroPageType).AnsweredIngressTextKey !== undefined

export const isBasicQuestionType = (
	variableToCheck: unknown
): variableToCheck is BasicQuestionType =>
	(variableToCheck as BasicQuestionType).showBirthdayQuestion !== undefined &&
	(variableToCheck as BasicQuestionType).showGenderQuestion !== undefined &&
	(variableToCheck as BasicQuestionType).showHeightQuestion !== undefined &&
	(variableToCheck as BasicQuestionType).showWeightQuestion !== undefined &&
	(variableToCheck as BasicQuestionType).calculateBmi !== undefined &&
	(variableToCheck as BasicQuestionType).bmiTagRanges !== undefined

export const isChoicesQuestionType = (
	variableToCheck: unknown
): variableToCheck is ChoicesQuestionType =>
	(variableToCheck as ChoicesQuestionType).order !== undefined &&
	(variableToCheck as ChoicesQuestionType).visible !== undefined &&
	(variableToCheck as ChoicesQuestionType).headerTextKey !== undefined &&
	(variableToCheck as ChoicesQuestionType).ingressTextKey !== undefined &&
	(variableToCheck as ChoicesQuestionType).alignment !== undefined &&
	(variableToCheck as ChoicesQuestionType).choicesVariant !== undefined &&
	(variableToCheck as ChoicesQuestionType).choices !== undefined

export const isSurveyStartType = (
	variableToCheck: unknown
): variableToCheck is SurveyStartType =>
	(variableToCheck as SurveyStartType).answeredHeaderTextKey !== undefined &&
	(variableToCheck as SurveyStartType).answeredIngressTextKey !== undefined

export const isChoiceType = (
	variableToCheck: unknown
): variableToCheck is ChoiceType =>
	(variableToCheck as ChoiceType).tags !== undefined &&
	(variableToCheck as ChoiceType).offset !== undefined

export const isBmiTagRangeType = (
	variableToCheck: unknown
): variableToCheck is bmiTagRangeType =>
	(variableToCheck as bmiTagRangeType).bmiTag !== undefined &&
	(variableToCheck as bmiTagRangeType).bmiStart !== undefined &&
	(variableToCheck as bmiTagRangeType).bmiEnd !== undefined

export const isSurveySubmitType = (
	variableToCheck: unknown
): variableToCheck is SurveySubmitType =>
	(variableToCheck as SurveySubmitType).choicesVariant === 'SurveySubmit' &&
	(variableToCheck as SurveySubmitType).headerTextKey !== undefined &&
	(variableToCheck as SurveySubmitType).ingressTextKey !== undefined

export const isAddScreeningPropertyPayloadType = (
	variableToCheck: unknown
): variableToCheck is addScreeningPropertyPayloadType =>
	(variableToCheck as addScreeningPropertyPayloadType).selectedPropertyId !==
		undefined &&
	(variableToCheck as addScreeningPropertyPayloadType).propertySettings !==
		undefined

export const isUpdateScreeningPropertyValuePayloadType = (
	variableToCheck: unknown
): variableToCheck is updateScreeningPropertyValuePayloadType =>
	(variableToCheck as updateScreeningPropertyValuePayloadType)
		.selectedPropertyId !== undefined &&
	(variableToCheck as updateScreeningPropertyValuePayloadType).value !==
		undefined &&
	((variableToCheck as updateScreeningPropertyValuePayloadType)
		.parentChoiceIndex !== undefined ||
		(variableToCheck as updateScreeningPropertyValuePayloadType).choiceIndex !==
			undefined)

export const isUpdateScreeningPropertyPayloadType = (
	variableToCheck: unknown
): variableToCheck is addScreeningPropertyPayloadType =>
	(variableToCheck as addScreeningPropertyPayloadType).selectedPropertyId !==
		undefined &&
	(variableToCheck as addScreeningPropertyPayloadType)
		.selectedPropertyParentId !== undefined &&
	(variableToCheck as addScreeningPropertyPayloadType).propertySettings !==
		undefined &&
	((variableToCheck as addScreeningPropertyPayloadType).parentChoiceIndex !==
		undefined ||
		(variableToCheck as addScreeningPropertyPayloadType).choiceIndex !==
			undefined)

export const isDeleteScrreningPropertyPayloadType = (
	variableToCheck: unknown
): variableToCheck is deleteScreeningPropertyPayloadType =>
	(variableToCheck as deleteScreeningPropertyPayloadType).selectedPropertyId !==
		undefined &&
	((variableToCheck as deleteScreeningPropertyPayloadType).parentChoiceIndex !==
		undefined ||
		(variableToCheck as deleteScreeningPropertyPayloadType).choiceIndex !==
			undefined)

// Typeguards for Survey
export const isBasicDetailsPageType = (
	variableToCheck: unknown
): variableToCheck is BasicDetailsPageType =>
	(variableToCheck as BasicDetailsPageType).ShowBirthdayQuestion !==
		undefined &&
	(variableToCheck as BasicDetailsPageType).ShowGenderQuestion !== undefined &&
	(variableToCheck as BasicDetailsPageType).ShowHeightQuestion !== undefined &&
	(variableToCheck as BasicDetailsPageType).ShowWeightQuestion !== undefined

export const isChoicesPageType = (
	variableToCheck: unknown
): variableToCheck is ChoicesPageType =>
	(variableToCheck as ChoicesPageType).AnswerOptions !== undefined &&
	(variableToCheck as ChoicesPageType).VerticalAlign !== undefined

export const isLastPageType = (
	variableToCheck: unknown
): variableToCheck is LastPageType =>
	(variableToCheck as LastPageType).$type === 'LastPage'

export const isApiDataType = (
	variableToCheck: unknown
): variableToCheck is ApiDataType =>
	(variableToCheck as ApiDataType).surveyDefinition !== undefined &&
	(variableToCheck as ApiDataType).translations !== undefined

export const isValidChoicesQuestionType = (
	question: unknown
): question is ChoicesQuestionType =>
	isChoicesQuestionType(question) &&
	question.choicesVariant !== 'checkbox' &&
	question.visible

export const isValidBasicQuestionType = (
	question: unknown
): question is BasicQuestionType =>
	isBasicQuestionType(question) && question.visible

export const isValidVisualQuestionType = (
	argument: unknown
): argument is ValidVisualQuestionType => {
	return (
		isValidBasicQuestionType(argument) ||
		isValidChoicesQuestionType(argument) ||
		isSurveySubmitType(argument)
	)
}

export const isQuestionPositionType = (
	argument: unknown
): argument is QuestionPositionType => {
	return (
		(argument as QuestionPositionType).questionIndex !== undefined &&
		(argument as QuestionPositionType).id !== undefined
	)
}

export const isChoicePositionType = (
	argument: unknown
): argument is ChoicePositionType => {
	return (
		(argument as ChoicePositionType).id !== undefined &&
		(argument as ChoicePositionType).height !== undefined
	)
}

export const isUpdateIndexPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateIndexPayload =>
	(variableToCheck as UpdateIndexPayload).tagRuleIndex !== undefined &&
	(variableToCheck as UpdateIndexPayload).newIndex !== undefined

export const isAddTagToInsertPayload = (
	variableToCheck: unknown
): variableToCheck is AddTagToInsertPayload =>
	(variableToCheck as AddTagToInsertPayload).tagRuleIndex !== undefined &&
	(variableToCheck as AddTagToInsertPayload).newTagToInsert !== undefined

export const isDeleteTagPayload = (
	variableToCheck: unknown
): variableToCheck is DeleteTagPayload =>
	(variableToCheck as DeleteTagPayload).tagRuleIndex !== undefined &&
	(variableToCheck as DeleteTagPayload).tagIndex !== undefined

export const isAddTagFilterPayload = (
	variableToCheck: unknown
): variableToCheck is AddTagFilterPayload =>
	(variableToCheck as AddTagFilterPayload).tagRuleIndex !== undefined &&
	(variableToCheck as AddTagFilterPayload).newFilterTag !== undefined

export const isUpdateTranslationPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateTranslationsPayloadType =>
	(variableToCheck as UpdateTranslationsPayloadType).key !== undefined &&
	(variableToCheck as UpdateTranslationsPayloadType).text !== undefined

export const isChoicePositionPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateChoicePositionPayloadType => {
	return (
		(variableToCheck as UpdateChoicePositionPayloadType).oldIndex !==
			undefined &&
		(variableToCheck as UpdateChoicePositionPayloadType).newIndex !== undefined
	)
}

export const isUpdateChoicesQuestionPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateQuestionPayloadType => {
	return (
		(variableToCheck as UpdateQuestionPayloadType).choicesVariant !==
			undefined ||
		(variableToCheck as UpdateQuestionPayloadType).visible !== undefined ||
		(variableToCheck as UpdateQuestionPayloadType).imageId !== undefined ||
		(variableToCheck as UpdateQuestionPayloadType).videoId !== undefined ||
		(variableToCheck as UpdateQuestionPayloadType).alignment !== undefined ||
		(variableToCheck as UpdateQuestionPayloadType).nicknameTextKey !==
			undefined ||
		(variableToCheck as UpdateQuestionPayloadType).hideWhenNotAnswered !==
			undefined ||
		(variableToCheck as UpdateQuestionPayloadType).subQuestionHeaderTextKey !==
			undefined ||
		(variableToCheck as UpdateQuestionPayloadType)
			.subQuestionNicknameTextKey !== undefined ||
		(variableToCheck as UpdateQuestionPayloadType).subQuestionIngressTextKey !==
			undefined ||
		(variableToCheck as UpdateQuestionPayloadType).observationId !== undefined
	)
}

export const isUpdateBasicQuestionPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateBasicQuestionPayloadType => {
	return (
		(variableToCheck as UpdateBasicQuestionPayloadType).visible !== undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).showGenderQuestion !==
			undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).showBirthdayQuestion !==
			undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).showHeightQuestion !==
			undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).showWeightQuestion !==
			undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).bmiTagRanges !==
			undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).showOnlyYear !==
			undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).calculateBmi !==
			undefined ||
		(variableToCheck as UpdateBasicQuestionPayloadType).observationId !==
			undefined
	)
}

export const isNewChoicePayload = (
	variableToCheck: unknown
): variableToCheck is NewSubchoicePayload =>
	(variableToCheck as NewSubchoicePayload).parentChoiceIndex !== undefined ||
	((variableToCheck as NewSubchoicePayload).headerTextKey !== undefined &&
		(variableToCheck as NewSubchoicePayload).ingressTextKey !== undefined)

export const isUpdateChoicePayload = (
	variableToCheck: unknown
): variableToCheck is UpdateChoicePayloadType => {
	return (
		(variableToCheck as UpdateChoicePayloadType).choiceIndex !== undefined &&
		((variableToCheck as UpdateChoicePayloadType).offset !== undefined ||
			(variableToCheck as UpdateChoicePayloadType).tags !== undefined ||
			(variableToCheck as UpdateChoicePayloadType).imageId !== undefined ||
			(variableToCheck as UpdateChoicePayloadType).nicknameTextKey !==
				undefined ||
			(variableToCheck as UpdateChoicePayloadType).subQuestionHeaderTextKey !==
				undefined ||
			(variableToCheck as UpdateChoicePayloadType)
				.subQuestionNicknameTextKey !== undefined ||
			(variableToCheck as UpdateChoicePayloadType).subQuestionIngressTextKey !==
				undefined ||
			(variableToCheck as UpdateChoicePayloadType).choicesVariant !== undefined ||
			(variableToCheck as UpdateChoicePayloadType).thresholdId !== undefined)
	)
}

export const isAddChoicePropertiesPayload = (
	variableToCheck: unknown
): variableToCheck is AddChoicePropertiesPayloadType => {
	return (
		((variableToCheck as AddChoicePropertiesPayloadType).parentChoiceIndex !==
			undefined ||
			(variableToCheck as AddChoicePropertiesPayloadType).choiceIndex !==
				undefined) &&
		(variableToCheck as AddChoicePropertiesPayloadType).type !== undefined &&
		(variableToCheck as AddChoicePropertiesPayloadType).id !== undefined &&
		(variableToCheck as AddChoicePropertiesPayloadType).grade !== undefined
	)
}

export const isDeleteChoicePayload = (
	variableToCheck: unknown
): variableToCheck is DeleteChoicePayloadType => {
	return (
		(variableToCheck as DeleteChoicePayloadType).parentChoiceIndex !==
			undefined ||
		(variableToCheck as DeleteChoicePayloadType).choiceIndex !== undefined
	)
}

export const isDeleteChoicePropertiesPayload = (
	variableToCheck: unknown
): variableToCheck is DeleteChoicePropertiesPayloadType =>
	(variableToCheck as DeleteChoicePropertiesPayloadType).parentChoiceIndex !==
		undefined ||
	(variableToCheck as DeleteChoicePropertiesPayloadType).choiceIndex !==
		undefined

export const isTranslationType = (
	variableToCheck: unknown
): variableToCheck is TranslationType =>
	(variableToCheck as TranslationType).changed !== undefined &&
	(variableToCheck as TranslationType).key !== undefined &&
	(variableToCheck as TranslationType).text !== undefined &&
	(variableToCheck as TranslationType).timeStamp !== undefined

export const isEmptyDataSet = (
	variableToCheck: unknown
): variableToCheck is EmptyDataSet =>
	(variableToCheck as EmptyDataSet).surveyDefinition === undefined &&
	(variableToCheck as EmptyDataSet).translations === undefined

export const isUpdateQuestionPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateQuestionVariantType =>
	(variableToCheck as UpdateQuestionVariantType).questionIndex !== undefined &&
	(variableToCheck as UpdateQuestionVariantType).newQuestionVariant !==
		undefined

export const isQuestionPositionPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateQuestionPositionPayloadType => {
	return (
		(variableToCheck as UpdateQuestionPositionPayloadType).oldIndex !==
			undefined &&
		(variableToCheck as UpdateQuestionPositionPayloadType).newIndex !==
			undefined
	)
}

export const isTranslationsType = (
	variableToCheck: unknown
): variableToCheck is TranslationsType =>
	(variableToCheck as TranslationsType)['en-US'] !== undefined ||
	(variableToCheck as TranslationsType)['nb-NO'] !== undefined ||
	(variableToCheck as TranslationsType)['sv-SE'] !== undefined ||
	(variableToCheck as TranslationsType)['da-DK'] !== undefined ||
	(variableToCheck as TranslationsType)['fi-FI'] !== undefined

export const isDeleteQuestionPayload = (
	variableToCheck: unknown
): variableToCheck is DeleteQuestionPayloadType =>
	(variableToCheck as DeleteQuestionPayloadType).questionIndex !== undefined

export const isToggleVisiblePayload = (
	variableToCheck: unknown
): variableToCheck is ToggleVisiblePayloadType =>
	(variableToCheck as ToggleVisiblePayloadType).questionIndex !== undefined

export const isSaveTagRulesPayload = (
	variableToCheck: unknown
): variableToCheck is SaveTagRulesPayloadType => {
	return (variableToCheck as SaveTagRulesPayloadType).tagRules !== undefined
}

export const isUpdatePotentialBasicAnswerType = (
	variableToCheck: unknown
): variableToCheck is UpdatePotentialBasicAnswerType => {
	return (
		(variableToCheck as UpdatePotentialBasicAnswerType).gender !== undefined ||
		(variableToCheck as UpdatePotentialBasicAnswerType).birthday !==
			undefined ||
		(variableToCheck as UpdatePotentialBasicAnswerType).weight !== undefined ||
		(variableToCheck as UpdatePotentialBasicAnswerType).height !== undefined
	)
}

export const isUpdatePotentialSliderAnswerType = (
	variableToCheck: unknown
): variableToCheck is UpdatePotentialSliderAnswerType => {
	return (
		(variableToCheck as UpdatePotentialSliderAnswerType).sliderIndex !==
		undefined
	)
}

export const isUpdatePotentialChoicesAnswerType = (
	variableToCheck: unknown
): variableToCheck is UpdatePotentialChoicesAnswerType => {
	return (
		(variableToCheck as UpdatePotentialChoicesAnswerType).choiceId !==
			undefined &&
		(variableToCheck as UpdatePotentialChoicesAnswerType).isChoiceChecked !==
			undefined &&
		(variableToCheck as UpdatePotentialChoicesAnswerType).choicesVariant !==
			undefined
	)
}

export type SurveyThreshold = {
	
description: string
groupId : number
id: number
max : number
min : number
observationId : number
outcome : string
}

export type SurveyObservation = {
	id: number
	code: string
	title: string
	type: string
	interval: number
	category: string
	thresholds: SurveyThreshold[]
}

export type SurveyConcept = {
	id: number
	title: string
	objective: number
	documentationUrl: string
	observations: SurveyObservation[]
}

export type SurveyHealthData = SurveyConcept[]
